<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <!-- <input type="hidden" name="tindakan" v-validate.continues="{tindakan:skemaRujukan }"  data-vv-as="tindakan"> -->
                            <!-- <small v-show="errors.first('tindakan')" class="text text-danger mr-1">{{ errors.first('tindakan') }}</small> -->
                            <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{ name: 'skema-rujukan' }"
                            >
                                <i class="flaticon2-back"></i>
                                {{ $t('button.previous') }}
                            </router-link>
                            <button class="btn btn-default btn-bold btn-upper btn-font-md" @click="save()" :disabled="!isComplete">
                                <i class="fa fa-save"></i>
                                 {{ $t('button.save') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                   <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.name') }}:</label>
                                <div class="col-lg-8">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="skemaRujukan.nama"
                                        v-validate="'required'"
                                        data-vv-as="Nama"
                                        name="Nama"
                                        :class="{'is-invalid': errors.has('Nama') }"/>
                                    <div v-show="errors.first('Nama')" class="invalid-feedback">{{ errors.first('Nama') }}</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.tanggalMulai') }}:</label>
                                <div class="col-lg-8">
                                <date-picker
                                    :changedValue.sync="skemaRujukan.tanggalMulai"
                                    id-date-picker="tanggal_mulai_skema"
                                    :picker-value="skemaRujukan.tanggalMulai"
                                    :is-validate="true"
                                    place-holder="DD/MM/YYYY"
                                    start-date
                                    end-date
                                    :btn-today="true"
                                    :btn-min="true"
                                    ></date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.tanggalSelesai') }}:</label>
                                <div class="col-lg-8">
                                <date-picker
                                    :changedValue.sync="skemaRujukan.tanggalSelesai"
                                    id-date-picker="tanggal_selesai_skema"
                                    :picker-value="skemaRujukan.tanggalSelesai"
                                    :is-validate="true"
                                    :is-compare="true"
                                    :date-to-compare="skemaRujukan.tanggalMulai"
                                    place-holder="DD/MM/YYYY"
                                    start-date
                                    end-date
                                    :btn-today="true"
                                    :btn-max="true"
                                    ></date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.kategori') }}:</label>
                                <div class="col-lg-8">
                                    <kategori-select
                                        v-model="skemaRujukan.kategoris"
                                        :validation="'required'"
                                        :multiple="true"
                                    ></kategori-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.guarantor') }}:</label>
                                <div class="col-lg-8">
                                    <jenis-penjamin-select
                                        v-model="skemaRujukan.jenisPenjamins"
                                        :validation="'required'"
                                        :multiple="true"
                                    ></jenis-penjamin-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group row">
                                <label class="col-lg-2 col-form-label">{{ $t('common.kelas') }}:</label>
                                <div class="col-lg-8">
                                    <button type="button" class="btn btn-default btn-bold btn-upper btn-font-sm" @click="openModalKelas()" >
                                        <i class="flaticon2-add-1"></i>
                                        {{ $t('button.addKelas') }}
                                    </button>
                                </div>
                            </div>
                            <div class="row pl-2 mb-5">
                                <ul class="list-group col-6" id="sortable">
                                    <li class="list-group-item" v-for="(item, index) in kelas" :key="item.id">
                                        <button class="btn btn-sm btn-outline-danger btn-icon btn-icon-md mr-5 border-white" title="Hapus" type="button" @click="deleteKelas(index)">
                                            <i class="la la-trash"></i>
                                        </button>
                                        {{item.nama}}
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group row" v-show="viewBtnParent">
                                <label class="col-lg-2 col-form-label">{{ $t('common.tindakan') }}:</label>
                                <div class="col-lg-8">
                                    <button type="button" class="btn btn-default btn-bold btn-upper btn-font-sm" @click="openModalTindakan()" >
                                        <i class="flaticon2-add-1"></i>
                                        {{ $t('button.addTindakan') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive" v-if="viewTable" :key="indexTable">
                                <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width="5%"></th>
                                        <th>{{ $t('common.tindakan') }}</th>
                                        <th width="15%" v-for="item in kelas" :key="item.id">{{ item.nama }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(parent , i) in skemaRujukan.parentTindakans" >
                                        <tr :key="parent.id">
                                            <th class="text-center">
                                                <button class="btn btn-sm m-auto btn-outline-danger btn-icon btn-icon-md mr-5 border-white" title="Hapus" type="button" @click="deleteParent(i)">
                                                    <i class="la la-trash"></i>
                                                </button>
                                            </th>
                                            <th>
                                                {{ parent.nama }}
                                                <button type="button" class="btn btn-default btn-bold border-white ml-1" @click="openModalSub(parent)">
                                                    <i class="flaticon2-add-1 pr-0"></i>
                                                </button>
                                            </th>
                                            <th v-for="item in kelas" :key="item.id"></th>
                                        </tr>
                                        <template v-if="!hideTable">
                                            <sub-repeater v-for="(sub,y) in parent.subTindakans" :data="sub" :indexParent="i" :indexSub="y" :key="`x${i}-${y}`" @deleteSub="deleteSub(i, y)"></sub-repeater>
                                        </template>
                                    </template>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-12 text text-danger text-right">
                            *Skema harus diisi bersama tindakan dan sub tindakan
                        </div>
                    </div>
                   </form>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <kelas-modal
                    v-if="showModalKelas"
                    @complete ="checkIsComplete"
                    :show-modal.sync="showModalKelas"
                    :modal-function="addKelasInSkemaRujukan"
                    :modal-name="'kelas-modal'"
                    :modal-data="kelas"
                ></kelas-modal>
                <tindakan-modal
                    v-if="showModalTindakan"
                    @complete ="checkIsComplete"
                    :show-modal.sync="showModalTindakan"
                    :modal-name="'tindakan-modal'"
                    :modal-data="skemaRujukan"
                ></tindakan-modal>
                <sub-modal
                    v-if="showModalSub"
                    @complete ="checkIsComplete"
                    :show-modal.sync="showModalSub"
                    :modal-name="'sub-modal'"
                    :modal-data="selectedParent"
                    :modal-kelas="kelas"
                ></sub-modal>
            </div>
        </div>
    </div>
</template>

<script>
import { Validator } from 'vee-validate';
import SweetAlertTemplate from "./../_general/SweetAlert";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import SkemaRujukan from "./../../model/skema-rujukan-model";
import Fee from "./../../model/fee-model";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const SkemaRujukanRepository = RepositoryFactory.get("skemaRujukan");
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import DatePicker from "./_components/CustomDatePicker";
import KategoriSelect from "./../_select/Kategori.vue";
import JenisPenjaminSelect from "./../_select/JenisPenjamin";
import LoadSpinner from "./../_general/LoadSpinner";
import SubRepeater from "./_repeaters/SubRepeater";
export default {
    components: {
        BaseHeader,
        BaseTitle,
        DatePicker,
        KategoriSelect,
        JenisPenjaminSelect,
        LoadSpinner,
        SubRepeater,
        KelasModal: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-kelas-skema" */ "./_components/KelasModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        TindakanModal: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-kelas-skema" */ "./_components/TindakanModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        SubModal: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-sub-skema" */ "./_components/SubModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    provide() {
        return {
        $validator: this.$validator
        };
    },
    data() {
        var vx = this;
        return {
            isComplete: true,
            skemaRujukan: null,
            isLoaded: false,
            headTitle: "Skema Rujukan",
            kelas: [],
            selectedParent: {},
            showModalKelas:false,
            showModalTindakan: false,
            showModalSub: false,
            viewTable: false,
            viewBtnParent: false,
            configNumber: {
                numeral: true,
                numeralPositiveOnly: true,
                numeralDecimalMark: ',',
                delimiter: '.'
            },
            indexTable: 0,
            hideTable: false,
        }
    },
    watch: {
        showModalKelas: function(val) {
            if(!val) {
                this.viewControl()
            }
        },
        showModalTindakan: function(val) {
            if(!val) {
                this.viewControl()
            }
        },
        showModalSub: function(val) {
            if(!val) {
                this.viewControl()
            }
        }
    },
    methods: {
        test: function(val){
            debugger;
        },
        save: function(){
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    if (vx.skemaRujukan.id) {
                        let instance = new SweetAlert().showConfirm({
                        swalTitle: "Apakah Anda yakin?",
                        swalText: "Perubahan data Skema Rujukan akan tersimpan.",
                        swalType: "info",
                        onConfirmButton: function () {
                            blockUI.blockPage();
                            vx.update();
                        },
                        onCancelButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                        swalTitle: "Apakah Anda yakin?",
                        swalText: "Data Skema Rujukan akan ditambahkan.",
                        swalType: "info",
                        onConfirmButton: function () {
                            blockUI.blockPage();
                            vx.create();
                        },
                        onCancelButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    }
                    return;
                }
            })
        },
        async update(){
            var vx = this;
            vx.skemaRujukan.kelasIdOrdered = vx.kelas.map(skema => skema.id);
            vx.constructDataBeforeSend();
            await SkemaRujukanRepository.updateSkema(vx.skemaRujukan)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Perubahan data Skema Rujukan berhasil disimpan.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                            vx.$router.push({ name: 'skema-rujukan-view', params: { id: response.data.id } });
                        }
                    });

                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Perubahan data Skema Rujukan gagal disimpan.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
        },
        async create(){
            var vx = this;
            vx.skemaRujukan.kelasIdOrdered = vx.kelas.map(skema => skema.id);
            vx.constructDataBeforeSend();
            await SkemaRujukanRepository.createSkema(vx.skemaRujukan)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Skema Rujukan berhasil ditambahkan.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                            vx.$router.push({ name: 'skema-rujukan-view', params: { id: response.data.id } });
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Skema Rujukan gagal ditambahkan.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
        },
        openModalKelas: function(){
            this.showModalKelas = true;
        },
        openModalTindakan: function(){
            this.showModalTindakan = true;
        },
        openModalSub: function(tindakan){
            this.selectedParent = tindakan;
            this.showModalSub = true;
        },
        getDataSkema: function(id) {
            SkemaRujukanRepository.getSkema(id)
            .then(res => {
                this.skemaRujukan = res.data;
                this.onInit();
            })
            .catch(error => {
                var msg = (error.message) ? error.message : error;
                this.showToastError('Skema Rujukan Data : ' + msg);
            })

        },
        onInit: function(){
            this.isLoaded = true;
            if(this.skemaRujukan.id){
                this.getKelas();
            }
            const vx = this;
            $(function() {
                $('#sortable').sortable({
                    start(event, ui) {
                        $(this).attr('data-previndex', ui.item.index());
                    },
                    update(event, ui) {
                        const newIndex = ui.item.index();
                        const oldIndex = $(this).attr('data-previndex');
                        vx.updateKelasOrder(oldIndex, newIndex);
                        $(this).removeAttr('data-previndex');
                    }
                });
                $('#sortable').disableSelection();
                $('#sortable').css( 'cursor', 'pointer' );
            });
            this.checkIsComplete();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
        currentDate: function() {
            return moment().format('YYYY-MM-DD')
        },
        getKelas: function() {
            let totalKelas = [];
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    const kelas = sub.fee.map(x => x.kelasCustom);
                    totalKelas = totalKelas.concat(kelas);
                });
            });
            const result = [];
            const map = new Map();
            totalKelas.forEach(item => {
                if(!map.has(item.id)){
                    map.set(item.id, true);
                    result.push(item);
                }
            });
            result.sort((a,b) => a.kelasOrder - b.kelasOrder);
            this.kelas = result;
            this.constructData();

        },
        constructData: function() {
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    if (sub.fee.length > 0) {
                        sub.usePersen = false;
                        sub.usePersen = sub.fee[0].usePersentase;
                        // check apakah sama semua fee nya
                        if (sub.usePersen) {
                            sub.isAll = sub.fee.map(x => x.persentase).every((val, i, arr) => val === arr[0]) &&
                                        sub.fee.map(x => x.biayaMin).every((val, i, arr) => val === arr[0]) &&
                                        sub.fee.map(x => x.biayaMax).every((val, i, arr) => val === arr[0])
                                        ? true : false;
                        } else {
                            sub.isAll = sub.fee.map(x => x.biaya).every((val, i, arr) => val === arr[0]);
                        }
                        // chck apakah fee nya ada yg kosong
                        if (sub.fee.length !== this.kelas.length) {
                            sub.isAll = false;
                            this.kelas.forEach(kls => {
                                if (!sub.fee.find(x => x.kelasCustom.id === kls.id)) {
                                    const fee = new Fee();
                                    fee.kelasCustom = kls;
                                    fee.usePersentase = sub.usePersen;
                                    sub.fee.push(fee);
                                }
                            });
                        }
                        sub.fee.sort((a, b) => a.kelasCustom.kelasOrder - b.kelasCustom.kelasOrder);
                        sub.fee.forEach(fee => {
                            fee.msgError = null;
                            fee.isReadonly = sub.isAll;
                        });
                    } else {
                        sub.isAll = false;
                        sub.usePersen = false;
                        this.kelas.forEach(kls => {
                            const fee = new Fee();
                            fee.kelasCustom = kls;
                            sub.fee.push(fee);
                        });
                    }
                });
            });
            this.viewControl();
        },
        updateKelasOrder: function(form, to, isDelete = false) {
            if (to > form) {
                for (let i = form; i <= to; i++) {
                    if (i === form && !isDelete) {
                        this.kelas[i].kelasOrder = to;
                    } else {
                        this.kelas[i].kelasOrder = this.kelas[i].kelasOrder - 1;
                    }
                    this.skemaRujukan.parentTindakans.forEach(parent => {
                        parent.subTindakans.forEach(sub => {
                            const indexfee = sub.fee.findIndex(x => x.kelasCustom.id === this.kelas[i].id);
                            sub.fee[indexfee].kelasCustom.kelasOrder = this.kelas[i].kelasOrder;
                            sub.fee.sort((a, b) => a.kelasCustom.kelasOrder - b.kelasCustom.kelasOrder);
                        });
                    });
                }
            } else {
                for (let i = form; i >= to; i--) {
                    if (i === form) {
                        this.kelas[i].kelasOrder = to;
                    } else {
                        this.kelas[i].kelasOrder = this.kelas[i].kelasOrder + 1;
                    }
                    this.skemaRujukan.parentTindakans.forEach(parent => {
                        parent.subTindakans.forEach(sub => {
                            const indexfee = sub.fee.findIndex(x => x.kelasCustom.id === this.kelas[i].id);
                            sub.fee[indexfee].kelasCustom.kelasOrder = this.kelas[i].kelasOrder;
                            sub.fee.sort((a, b) => a.kelasCustom.kelasOrder - b.kelasCustom.kelasOrder);
                        });
                    });
                }
            }
            this.kelas.sort((a, b) => a.kelasOrder - b.kelasOrder);
            $(document).scrollTop(100);
        },
        deleteKelas: function(indexKelas) {
            const vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Kelas di skema akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    new Promise((resolve, reject) => {
                        vx.removeKelas(indexKelas,resolve);
                    }).then((response) => {
                        let instance2 = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Kelas berhasil terhapus.",
                            swalType: "success",
                            onConfirmButton: function () {
                                blockUI.unblockPage();
                            }
                        });
                    });
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        removeKelas: function(indexKelas , resolve){
            const index = (indexKelas == this.kelas.length - 1) ? indexKelas - 1 : indexKelas;
            const id = this.kelas[indexKelas].id;
            this.removeKelasInSkemaRujukan(id);
            this.kelas.splice(indexKelas, 1);
            if(this.kelas.length > 0){
              this.updateKelasOrder(index, this.kelas.length - 1, true);
            }
            this.checkIsComplete();
            this.viewControl();
            resolve(true);
        },
        deleteParent: function(indexParent) {
            const vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Tindakan di skema akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    new Promise((resolve, reject) => {
                        vx.removeParent(indexParent, resolve);
                    }).then((response) => {
                        let instance2 = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Tindakan di skema berhasil terhapus.",
                            swalType: "success",
                            onConfirmButton: function () {
                                blockUI.unblockPage();
                            }
                        });
                    });
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });

        },
        removeParent: function(indexParent, resolve) {
            this.skemaRujukan.parentTindakans.splice(indexParent, 1);
            this.checkIsComplete();
            this.viewControl();
            resolve(true);
        },
        deleteSub: function(indexParent, indexSub){
            const vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Sub Tindakan di skema akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    new Promise((resolve, reject) => {
                        vx.skemaRujukan.parentTindakans[indexParent].subTindakans.splice(indexSub, 1);
                        vx.checkIsComplete();
                        vx.hideTable = true
                        resolve(true);
                        vx.hideTable = false
                    }).then((response) => {
                        let instance2 = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Sub Tindakan di skema berhasil terhapus.",
                            swalType: "success",
                            onConfirmButton: function () {
                                blockUI.unblockPage();
                            }
                        });
                    });
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        removeKelasInSkemaRujukan: function(id) {
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    const indexFee = sub.fee.findIndex(x => x.kelasCustom.id === id);
                    sub.fee.splice(indexFee, 1);
                });
            });
        },
        addKelasInSkemaRujukan: function(kls) {
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    const fee = new Fee();
                    fee.kelasCustom = kls;
                    if (sub.fee.length === 0) {
                        sub.usePersen = false;
                        sub.isAll = false;
                    } else {
                        if (!sub.usePersen) {
                            fee.biaya = sub.isAll ? sub.fee[0].biaya : null;
                            fee.isReadonly = sub.isAll;
                            fee.usePersentase = false;
                        } else {
                            fee.isReadonly = sub.isAll;
                            fee.usePersentase = true;
                            fee.persentase = sub.isAll ? sub.fee[0].persentase : null;
                            fee.biayaMin = sub.isAll ? sub.fee[0].biayaMin : null;
                            fee.biayaMax = sub.isAll ? sub.fee[0].biayaMax : null;
                        }
                    }
                    sub.fee.push(fee);
                });
            });
        },
        viewControl: function() {
            if (this.kelas.length > 0) {
                this.viewBtnParent = true;
                if (this.skemaRujukan.parentTindakans.length > 0) {
                    this.viewTable = true;
                } else {
                    this.viewTable = false;
                }
            } else {
                this.viewBtnParent = false;
                this.viewTable = false;
            }
        },
        constructDataBeforeSend: function(){
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    sub.fee.forEach((fee, index) => {
                        if (fee.usePersentase) {
                            if (!fee.persentase && !fee.biayaMax && !fee.biayaMin) {
                                sub.fee.splice(index, 1, null);
                            }
                        } else {
                            if (!fee.biaya) {
                                sub.fee.splice(index, 1, null);
                            }
                        }
                    });
                    sub.fee = sub.fee.filter(x => x !== null);
                });
            });
        },
        checkIsComplete: function(data = true){
            let bool = true;
            if (this.skemaRujukan.parentTindakans.length < 1) {
                bool = false;
            } else {
                this.skemaRujukan.parentTindakans.forEach(parent => {
                    if (parent.subTindakans.length < 1) {
                        bool = false;
                    } else {
                        parent.subTindakans.forEach(sub => {
                            if(sub.fee.length < 1) {
                                bool = false;
                            }
                        })
                    }
                });
            }
            this.isComplete = bool;
        }
    },
    created(){
        if(this.$route.params.id){
            this.headTitle = "Skema Rujukan Edit";
            this.getDataSkema(this.$route.params.id)
        } else {
            this.headTitle = "Skema Rujukan Add";
            this.skemaRujukan = new SkemaRujukan;
            this.onInit();
        }
    },
    mounted() {
        Validator.extend('tindakan', {
            getMessage: field => 'Silakan lengkapi tindakan yang dibutuhkan',
            validate: (value , skemaRujukan) => {
                let bool = true;
                if (skemaRujukan.parentTindakans.length < 1) {
                    bool = false;
                } else {
                    skemaRujukan.parentTindakans.forEach(parent => {
                        if (parent.subTindakans.length < 1) {
                            bool = false;
                        } else {
                            parent.subTindakans.forEach(sub => {
                                if(sub.fee.length < 1) {
                                    bool = false;
                                }
                            })
                        }
                    });
                }
                return bool;
            }
        })
    }
}
</script>

<style>

</style>
