<template>
    <td>
        <div class="row p-2">
            <template v-if="!fee.usePersentase">
                <cleave class="form-control text-right"
                        :options="configNumber"
                        v-model.number="fee.biaya"
                        :readonly="(index === 0) ? false : fee.isReadonly"
                        :class="{'readonly' : (index === 0) ? false : fee.isReadonly}"
                        @keyup.native="changeFee($event,'BIAYA')"
                        placeholder="Biaya"> </cleave>
            </template>
            <template v-else>
                <cleave class="form-control text-right mb-2 no-arrow"
                    :options="configDecimal"
                    v-model.number="fee.persentase"
                    placeholder="Persentase"
                    @keyup.native="changeFee($event,'PERSENTASE')"
                    v-validate.continues="{requiredPersen:fee }"
                    data-vv-as="Persentase"
                    :name="'persen'+uid"
                    :class="[{'is-invalid': errors.has('persen'+uid) }, {'readonly' : (index === 0) ? false : fee.isReadonly}]"
                    :readonly="(index === 0) ? false : fee.isReadonly"
                    > </cleave>
                <div v-show="errors.first('persen'+uid)" class="invalid-feedback">{{ errors.first('persen'+uid) }}</div>
                <cleave class="form-control mb-2 text-right"
                    :options="configNumber"
                    v-model.number="fee.biayaMin"
                    placeholder="Biaya Minimum"
                    @keyup.native="changeFee($event,'MIN')"
                    v-validate.continues="{requiredMin:fee }"
                    data-vv-as="Biaya Min"
                    :name="'min'+uid"
                    :class="[{'is-invalid': errors.has('min'+uid) },{'readonly' : (index === 0) ? false : fee.isReadonly}]"
                    :readonly="(index === 0) ? false : fee.isReadonly"
                    > </cleave>
                <div v-show="errors.first('min'+uid)" class="invalid-feedback">{{ errors.first('min'+uid) }}</div>
                <cleave class="form-control mb-2 text-right"
                    :options="configNumber"
                    v-model.number="fee.biayaMax"
                    placeholder="Biaya Maksimum"
                    @keyup.native="changeFee($event,'MAX')"
                    :class="{'readonly' : (index === 0) ? false : fee.isReadonly}"
                    :readonly="(index === 0) ? false : fee.isReadonly"
                    > </cleave>
            </template>
            <div class="text text-danger">{{ fee.msgError }}</div>
        </div>
    </td>
</template>

<script>
import { Validator } from 'vee-validate';
import { uuid } from 'vue-uuid';
export default {
    inject: ["$validator"],
    props: ['data', 'index'],
    data() {
        var vx = this;
        return {
            fee: {},
            uid: null,
            configNumber: {
                numeral: true,
                numeralPositiveOnly: true,
                numeralDecimalMark: ',',
                delimiter: '.',
                numeralDecimalScale: 0
            },
            configDecimal:{
                numeral:true,
                numeralPositiveOnly: true,
                numeralDecimalMark: '.',
                delimiter: '',
                numeralDecimalScale: 2
            }
        };
    },
    methods: {
        changeFee(event,field){
            event.target.value = event.target.value == "" ? null : event.target.value;
            const value = (field == 'PERSENTASE') ? event.target.value : event.target.value.replace(/\D/g, '');
            this.$emit('updateFee',value,field);
        }
    },
    created() {
        this.fee = this.data;
        this.uid = this.$uuid.v4()
    },
    mounted(){
        Validator.extend('requiredMin', {
            getMessage: field => 'Biaya Min harus lebih kecil dari Biaya Max',
            validate: (value , fee) => {
                if(fee.biayaMax && value){
                    return (fee.biayaMax >= value);
                }else{
                    return true;
                }
            }
        }),
        Validator.extend('requiredPersen', {
            getMessage: field => 'Persentase harus diisi',
            validate: (value , fee) => {
                if(fee.biayaMin || fee.biayaMax){
                    return (value) ? true : false;
                }else{
                    return true;
                }
            }
        })
    },
    watch: {
        "fee.biaya": function(val){
            this.fee.biaya = (val == '') ? null : val;
        },
        "fee.persentase": function(val){
            this.fee.persentase = (val == '') ? null : val;
        },
        "fee.biayaMin": function(val){
            this.fee.biayaMin = (val == '') ? null : val;
        },
        "fee.biayaMax": function(val){
            this.fee.biayaMax = (val == '') ? null : val;
        },
    }
}
</script>

<style>

</style>
