<template>
    <tr>
        <td class="text-center">
            <button class="btn btn-sm m-auto btn-outline-danger btn-icon btn-icon-md mr-5 border-white" title="Hapus" type="button" @click="deleteSub()">
                <i class="la la-trash"></i>
            </button>
        </td>
        <td>
            <div class="row p-2">
                {{ data.nama }}
            </div>
            <div class="row p-2">
                <div class="kt-checkbox-list kt-radio-list">
                    <label class="kt-checkbox">
                        <input type="checkbox" v-model="isAll" @change="changeAll()"> Samakan semua<span></span>
                    </label>
                    <label class="kt-radio">
                        <input type="radio" :value="false" v-model="usePersen" @change="changeMode()" > Biaya (Rp) <span></span>
                    </label>
                    <label class="kt-radio">
                        <input type="radio" :value="true" v-model="usePersen" @change="changeMode()" > Persentase (%) <span></span>
                    </label>
                </div>
            </div>
        </td>
        <fee-repeater v-for="(fee,i) in fees" :data="fee" :index="i" :key="'y'+i" @updateFee="updateFee"></fee-repeater>
    </tr>
</template>

<script>
import FeeRepeater from './FeeRepeater';
export default {
    inject: ["$validator"],
     provide() {
        return {
            $validator: this.$validator
        };
    },
    components: {
        FeeRepeater
    },
    props: {
        data: { type: Object, default: ()=> {} },
        indexSub: { type: Number },
        indexParent: { type: Number }
    },
    // watch:{
    //     fees(){
            // handler: function (val, oldVal) {
            //    debugger;
            // },
            // deep: true 
    //     }
    // },
    data() {
        var vx = this;
        return {
            isAll: {type: Boolean, default: false},
            usePersen: {type: Boolean, default: false},
            subTindakan: {type: Object},
            fees: {type: Array, default:[]}
        };
    },
    methods: {
        updateFee: function(value,field){
            if(this.isAll){
                this.data.fee.forEach(fee => {
                   if (field == 'BIAYA') {
                        fee.biaya = value === '' ? null : parseInt(value, 0);
                    } else if (field === 'PERSENTASE') {
                        fee.persentase = value === '' ? null : parseFloat(value);
                    } else if (field === 'MIN') {
                        fee.biayaMin = value === '' ? null : parseInt(value, 0);
                    } else if (field === 'MAX') {
                        fee.biayaMax = value === '' ? null : parseInt(value, 0);
                    }
                })
            }
        },
        changeAll: function(){
            this.data.isAll = this.isAll;
            this.data.fee.forEach(fee => {
                fee.isReadonly = this.data.isAll;
                fee.biaya = null;
                fee.persentase = null;
                fee.biayaMax = null;
                fee.biayaMin = null;
            });
        },
        changeMode: function(){
            this.data.usePersen = this.usePersen;
            this.data.fee.forEach(fee => {
                fee.usePersentase =  this.data.usePersen;
                fee.biaya = null;
                fee.persentase = null;
                fee.biayaMax = null;
                fee.biayaMin = null;
            });
        },
        deleteSub: function(){
            this.$emit('deleteSub', this.indexParent , this.indexSub);
        }
    },
    mounted() {
        this.isAll = this.data.isAll;
        this.usePersen = this.data.usePersen;
        this.subTindakan = this.data;
        this.fees = this.data.fee
    }
}
</script>

<style>

</style>
